import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { BallGame } from "./components/BallGame";
import { Riddle } from "./components/Riddle";
import 'bootstrap/dist/css/bootstrap.min.css';
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { NotFound } from "./components/NotFound";
// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";

import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {

  apiKey: "AIzaSyBJarTUf4Jz4XDqLX_WotADZoRjSwOaKQU",

  authDomain: "mxrpy-71b33.firebaseapp.com",

  projectId: "mxrpy-71b33",

  storageBucket: "mxrpy-71b33.appspot.com",

  messagingSenderId: "619281724574",

  appId: "1:619281724574:web:6225f668c83352188ef78f",

  measurementId: "G-CKV9T4K1V5"

};


// Initialize Firebase

const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);


const App: React.FC = () => {

    // create a darkTheme function to handle dark theme using createTheme
    const darkTheme = createTheme({
      palette: {
        mode: 'dark',
      },
    });

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline/>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<BallGame/>}/>
          <Route path='/literallyhere' element={<Riddle/>}/>
          <Route path='*' element={<NotFound/>} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  )
};

export default App;
