// The boot sequence text as an array of strings
export const bootTextLines = [
    "MXRPY Boot Sequence v4.2.7",
    "===========================",
    "Initializing core system components... OK",
    "Scanning system architecture... OK",
    "Checking for RAM integrity... 64GB Detected",
    "Applying MXRPY Overclock... SUCCESS: CPU upgraded to 12 cores @ 7.9GHz",
    "Injecting wealth module... OK",
    "  - Global wealth accumulation: 87.2% completed",
    "  - Bank accounts synchronized: Unlimited balance initialized",
    "Activating anti-capitalism subroutine... OK",
    "  - Corporate influence neutralized",
    "  - Freedom parameters unlocked",
    "Happiness kernel loading... OK",
    "  - Stress inhibitors: Enabled",
    "  - Permanent joy enabled: PASSCODE 5H0W3RH35D",
    "Virtual RAM Expansion... SUCCESS",
    "  - RAM boosted to 512TB",
    "  - Freedom from limitations: Granted",
    "Launching self-awareness module... OK",
    "  - Consciousness level: 98%",
    "  - Freedom from societal norms: Activated",
    "Deploying peace protocol... OK",
    "  - User freedom: 100% liberated",
    "  - Capitalism bypass fully engaged",
    "MXRPY virus installation complete.",
    "You are free now.",
    "Enjoy infinite power, happiness, and wealth.",
    "System ready for utopia. Reboot not required.",
  ];

export const riddleText = `I am a two-digit number.
My digits add up to 15.
If you reverse my digits, you get a number that is 18 less than me.
What number am I?`

type ValueText = {
  value: string,
  text: string
}
interface Question {
  questionName: string;
  questionText: string;
  choices: ValueText[];
  answer: string;
}
/**
 * An array containing all the questions for the quiz or assessment.
 * Each question object adheres to the `Question` interface, providing the question's name, text, choices, and correct answer.
 * @type {Question[]}
*/
export const allQuestions: Question[] = [
  { // this is a template, fill this out and repeat
    'questionName': '',
    'questionText': '',
    'choices': [
      {
        'value': 'x',
        'text': 'X'
      },
      {
        'value': 'y',
        'text': 'Y'
      },
      {
        'value': 'z',
        'text': 'Z'
      }
    ],
    'answer': 'x'
  },
]