import { useEffect, useRef, useState } from "react";
import { bootTextLines } from "../consts";
import Particles from "./Particles";
import './ballgame.scss'
import hoop from '../assets/pixil-frame-0(2).png'
import InstagramLink from "./InstagramLink";
import successSound from "../assets/success.mp3"

export const BallGame : React.FC = () => {
  const [points, setPoints] = useState(0);
  const [infected, setInfected] = useState(false);
  const [bootSequence, setBootSequence] = useState(false);
  const [visibleLines, setVisibleLines] = useState<string[]>([]);
  const dragItem = useRef<string | null>(null);

  // Ball-dragging logic
  const onDragStart = (e: React.DragEvent<HTMLSpanElement>) => {
    dragItem.current = e.currentTarget.id;
  };

  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (dragItem.current === "o-letter") {
      setPoints(points + 1);

      const audio = new Audio(successSound); 
      audio.play();
    }
    dragItem.current = null;
  };

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  // Effect to trigger infection sequence when points reach 3
  useEffect(() => {
    if (points >= 3) {
      setInfected(true);

      // After 3 seconds, switch to boot screen
      setTimeout(() => {
        setBootSequence(true);
      }, 3000);

      // After switching to boot screen, generate lines with random delay
      let totalTime = 0;
      const textLines = () => {
        bootTextLines.forEach((line) => {
          const delay = Math.random() * 3/10;
          totalTime += delay;
  
          setTimeout(() => {
            setVisibleLines((prevLines) => [...prevLines, line]);
          }, totalTime * 800);
          // redirect to different page
          setTimeout(() => {
            // LOL THIS IS FUNNY AS FUCK
            window.alert('NIGHTMARE NIGHTMARE NIGHTMARE')
            console.error("UH OH SHITS GONE BAD, HELP HELP THEY'RE GETTING IN! FUCK FUCK FUCK")
            // window.location.href = 'https://www.google.com';
          }, (totalTime * 800) + 3000)
        });
      }

      setTimeout(() => {textLines()}, 2500)
    }
  }, [points]);

  return (
    <div className={`app ${infected ? "infected" : ""}`}>
      <Particles
        className="particles"
        quantity={1000}
        color={'808080'}
        staticity={5}
        vx={Math.floor(Math.random()*2)}
        vy={-1 * Math.floor(Math.random()*2)}
      />
      {!infected && (
        <>
          <h1 className="mxrpy-animation">MXRPY</h1>
          <h2 className="shop">
            SH<span id="o-letter" draggable onDragStart={onDragStart}>O</span>P
          </h2>
          <span className="shop" onClick={() => window.location.replace('/literallyhere')}>
            SH<span id="o-letter" draggable onDragStart={onDragStart}>O</span>P
          </span>
          <div><InstagramLink showUserName={false} username={'m.xrpy'}/></div>
          <img className='hoop' src={hoop} alt='hoop' onDrop={onDrop} onDragOver={onDragOver}></img>
          <div className="scoreboard">Points: {points}</div>
        </>
      )}

      {infected && !bootSequence && (
        <div className="infection-message">Stop playing</div>
      )}

      {bootSequence && (
        <div className="boot-sequence">
          {visibleLines.map((line, index) => (
            <div key={index}><span>{line}</span></div>
          ))}
        </div>
      )}
    </div>
  );
}
