import React from 'react';
import InstagramIcon from '@mui/icons-material/Instagram';
import Link from '@mui/material/Link';

interface InstagramLinkProps {
    username: string,
    showUserName: Boolean
}

const InstagramLink = (props: InstagramLinkProps) => {
  const instagramUrl = `https://www.instagram.com/${props?.username}`;

  return (
    <Link href={instagramUrl} target="_blank" rel="noopener noreferrer">
      <InstagramIcon sx={{ fontSize: 30, marginRight: 1 }} /> {/* Adjust size and margin as needed */}
      {
        props.showUserName && 
        <span>{props.username}</span>
    }
    </Link>
  );
};

export default InstagramLink;