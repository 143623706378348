import { Button, FormGroup, Input } from 'reactstrap'
import './riddle.scss'
import { useState } from 'react';
import { riddleText } from '../consts';

export const Riddle = () => {
  const [inputValue, setInputValue] = useState(''); // Store the input value
  const [userInput, setUserInput] = useState(''); // Store the input value

  const riddleAnswer = '78'

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value); // Update the  state when input changes
  };

  const redirectWindow = () => {
    window.location.replace('/')
    return null
}

  return (
    <div className='centered-container'>
      <div className='align-items-center'>
        <span className='centered-riddle-text mb-3'>{riddleText}</span>
        <div className='d-flex justify-content-center'>
          <FormGroup>
            {/* <Label for="exampleInput">Enter some text:</Label> */}
            <Input 
              type="text" 
              name="exampleInput" 
              id="exampleInput" 
              placeholder="you're an idiot"
              value={inputValue} // Bind the input value to the state
              onChange={handleChange} // Handle changes to the input
            />
        </FormGroup>
        </div>
        <div className='d-flex justify-content-center'>
        <Button
          onClick={() => setUserInput(inputValue)}
        >
          Enter
        </Button>
        </div>
        {
          userInput === riddleAnswer && redirectWindow()
        }
    </div>
   </div>
  )
}