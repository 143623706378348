import './notfound.scss'

export const NotFound = () => (
  <div className="not-found-container">
  <div className="animated-404">
    404
  </div>
  <p>Oops! The page you're looking for doesn't exist.</p>
  <a href="/">Go back home</a>
</div>
)